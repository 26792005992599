var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-tab-overview"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('statistics-card-vertical', {
    attrs: {
      "change": _vm.spendingOptions.change,
      "color": _vm.spendingOptions.color,
      "icon": _vm.spendingOptions.icon,
      "statistics": "$ ".concat(_vm.userId.spending.total),
      "stat-title": _vm.spendingOptions.statTitle,
      "subtitle": "Included ".concat(_vm.userId.spending.credits_total, " HealthCoins Credits")
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('statistics-card-vertical', {
    attrs: {
      "change": _vm.orderOptions.change,
      "color": _vm.orderOptions.color,
      "icon": _vm.orderOptions.icon,
      "statistics": "".concat(_vm.userId.order.count),
      "stat-title": _vm.orderOptions.statTitle,
      "subtitle": "Last order at : ".concat(_vm.date(_vm.userId.order.last_created_at))
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Top Purchase Category")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ChartjsComponentHorizontalBarChart', {
    attrs: {
      "height": 400,
      "data": _vm.categoryData,
      "options": _vm.chartjsData.horizontalBarChart.options
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v("Top Purchase Product")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.userId.product.top_purchases
    },
    scopedSlots: _vm._u([{
      key: "item.data.media",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('main-media', {
          attrs: {
            "media": item.data.media
          }
        })];
      }
    }, {
      key: "item.data.product_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticStyle: {
            "min-width": "120px"
          }
        }, [_vm._v(" " + _vm._s(item.data.product_number) + " "), item.data.merchant_product_id ? _c('small', [_vm._v("(" + _vm._s(item.data.merchant_product_id) + ") ")]) : _vm._e()])];
      }
    }, {
      key: "item.count",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex align-center",
          staticStyle: {
            "min-width": "150px"
          }
        }, [_vm._v(" " + _vm._s(item.count) + " ")])];
      }
    }, {
      key: "item.data.product",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('router-link', {
          staticClass: "text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",
          attrs: {
            "to": {
              name: 'product-view',
              params: {
                id: item.data.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.data.title.en) + " ")])], 1)];
      }
    }, {
      key: "item.data.max_sku_price",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center",
          staticStyle: {
            "min-width": "150px"
          }
        }, [_vm._v(" " + _vm._s(item.data.reference_price.min_sku_price) + " - " + _vm._s(item.data.reference_price.max_sku_price) + " ")])];
      }
    }, {
      key: "item.data.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex align-center",
          staticStyle: {
            "min-width": "100px"
          }
        }, [_vm._v(" " + _vm._s(item.data.status) + " ")])];
      }
    }, {
      key: "item.data.updated_at",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex align-center",
          staticStyle: {
            "min-width": "200px"
          }
        }, [_vm._v(" " + _vm._s(_vm.date(item.data.updated_at)) + " ")])];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }