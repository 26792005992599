<template>
  <div class="user-tab-overview">
    <v-row>
      <!-- calander part : wait for endpoint filter function  -->
      <!-- <v-col
      cols="12"
    >
      <v-text-field
        v-model="dateRangeText"
        label="Date range"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
      </v-col>
    <v-col
      cols="12"
    >
      <v-date-picker
        v-model="datesRange"
        range
      ></v-date-picker>
    </v-col> -->
      <v-col
        cols="12"
        md="6"
      >
        <statistics-card-vertical
          :change="spendingOptions.change"
          :color="spendingOptions.color"
          :icon="spendingOptions.icon"
          :statistics="`$ ${userId.spending.total}`"
          :stat-title="spendingOptions.statTitle"
          :subtitle="`Included ${userId.spending.credits_total} HealthCoins Credits`"
        >
        </statistics-card-vertical>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <statistics-card-vertical
          :change="orderOptions.change"
          :color="orderOptions.color"
          :icon="orderOptions.icon"
          :statistics="`${userId.order.count}`"
          :stat-title="orderOptions.statTitle"
          :subtitle="`Last order at : ${date(userId.order.last_created_at)}`"
        >
        </statistics-card-vertical>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>Top Purchase Category</v-card-title>
          <v-col cols="12">
            <ChartjsComponentHorizontalBarChart
              :height="400"
              :data="categoryData"
              :options="chartjsData.horizontalBarChart.options"
            />
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card-title>Top Purchase Product</v-card-title>
            <v-data-table
              :headers="tableColumns"
              :items="userId.product.top_purchases"
            >
              <template #[`item.data.media`]="{item}">
                <main-media :media="item.data.media" />
              </template>

              <!-- ID -->
              <template #[`item.data.product_number`]="{item}">
                <div style="min-width: 120px;">
                  {{ item.data.product_number }}
                  <small v-if="item.data.merchant_product_id">({{ item.data.merchant_product_id }})
                  </small>
                </div>
              </template>

              <template #[`item.count`]="{item}">
                <div
                  class="d-flex align-center"
                  style="min-width: 150px;"
                >
                  {{ item.count }}
                </div>
              </template>

              <!-- Name -->
              <template #[`item.data.product`]="{item}">
                <div class="d-flex align-center">
                  <router-link
                    :to="{ name: 'product-view', params: { id : item.data.id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.data.title.en }}
                  </router-link>
                </div>
              </template>

              <!-- Price -->
              <template #[`item.data.max_sku_price`]="{item}">
                <div
                  class="d-flex align-center"
                  style="min-width: 150px;"
                >
                  {{ item.data.reference_price.min_sku_price }} -
                  {{ item.data.reference_price.max_sku_price }}
                </div>
              </template>
              <template #[`item.data.status`]="{item}">
                <div
                  class="d-flex align-center"
                  style="min-width: 100px;"
                >
                  {{ item.data.status }}
                </div>
              </template>
              <template #[`item.data.updated_at`]="{item}">
                <div
                  class="d-flex align-center"
                  style="min-width: 200px;"
                >
                  {{ date(item.data.updated_at) }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartjsComponentHorizontalBarChart from '@/@core/components/chart-js/ChartjsComponentHorizontalBarChart.vue'
import chartjsData from '@/@core/components/chart-js/chartjsData'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import { MainMedia } from '@/components'
import { date } from '@core/utils/filter'
import {
mdiCurrencyUsd,
mdiDeleteOutline,
mdiDotsVertical, mdiPencilOutline,
mdiTruckDeliveryOutline,
} from '@mdi/js'
import {
computed, onBeforeMount,
ref,
} from '@vue/composition-api'
import moment from 'moment'

export default {
  components: {
    StatisticsCardVertical,
    ChartjsComponentHorizontalBarChart,
    MainMedia,
  },
  props: {
    userId: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      const labels = []
      const data = []
      const bgColor = []
      props.userId.category.top_purchases.forEach((item, index) => {
        labels.push(item.data.name.en)
        data.push(item.count)
        bgColor.push(index % 2 ? '#aaa' : '#28dac6')
      })

      categoryData.value.labels = labels
      categoryData.value.datasets[0].data = data
      categoryData.value.datasets[0].backgroundColor = bgColor
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
      datesRange.value = [startOfMonth, endOfMonth]
    })

    const spendingOptions = {
      statTitle: 'Spending total',
      icon: mdiCurrencyUsd,
      color: 'success',
    }

    const orderOptions = {
      statTitle: 'Order total',
      icon: mdiTruckDeliveryOutline,
      color: 'warning',
    }

    const categoryData = ref({
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: 'transparent',
          barThickness: 15,
        },
      ],
    })

    const tableColumns = [
      {
        text: 'IMAGE',
        value: 'data.media',
        sortable: false,
      },
      {
        text: 'ID',
        value: 'data.product_number',
      },
      {
        text: 'TOTAL PURCHASE',
        value: 'count',
      },
      {
        text: 'PRODUCT',
        value: 'data.product',
        sortable: false,
      },
      {
        text: 'PRICE',
        value: 'data.max_sku_price',
      },
      {
        text: 'STATUS',
        value: 'data.status',
      },
      {
        text: 'UPDATED AT',
        value: 'data.updated_at',
      },
    ]

    const tableColumnHeaders = [
      {
        text: 'Category',
        value: 'category',
        sortable: false,
      },
      {
        text: 'Key',
        value: 'key',
        sortable: false,
      },
      {
        text: 'Value',
        value: 'value',
        sortable: false,
      },
      {
        text: 'Value Type',
        value: 'value_type',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ]

    const datesRange = ref([])

    const dateRangeText = computed(() => datesRange.value.join(' ~ '))

    return {
      tableColumnHeaders,
      icons: {
        mdiDotsVertical,
        mdiTruckDeliveryOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
      spendingOptions,
      orderOptions,
      date,
      chartjsData,
      categoryData,
      tableColumns,
      datesRange,
      dateRangeText,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
